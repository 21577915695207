import { useApolloClient } from '@apollo/client';
import cookie from 'js-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  CountryCodeEnum,
  GetLoggedUserQueryResult,
  Provider,
  ProviderAddress,
  ShippingAddressInput,
  useGetLoggedProviderQuery,
  useGetLoggedUserQuery,
  useLoginMutation,
} from '~/operations';
import { userLoggedIn } from '~/state/user';
import { useNavigation } from '~/utils/navigation';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';
import { Event, EventContext } from './Event';
import { getAuthCookie, setAuthCookie } from '~/utils/cookie';

export const COOKIE_PROVIDER_ID = process.env.COOKIE_PROVIDER_ID || 'providerId';

export interface AuthenticationInterface {
  login: (email: string, password: string) => Promise<string | undefined>;
  loggingIn: boolean;
  logout: () => Promise<void>;
  token?: string;
  jwt?: JwtPayload;
  isLoggedIn: boolean;
  user?: NonNullable<GetLoggedUserQueryResult['data']>['backofficeUser'];
  provider?: Partial<Provider | null>;
  providerId?: string;
  loadingProvider: boolean;
  loadingUser: boolean;
  setBuyer: (buyer?: AuthenticatedBuyer) => void;
  providerAddress: ShippingAddressInput | null;
  setToken: (id: string) => void;
  postalCode?: string;
  isSoftLogin: boolean;
}

export type AuthenticatedBuyer = NonNullable<AuthenticationInterface['user']>['buyers'][number];

export const AuthenticationContext = createContext<AuthenticationInterface>({} as any);

export const Authentication: React.FC = ({ children }) => {
  const { cache } = useApolloClient();
  const [token, setTokenState] = useState(getAuthCookie());
  const [providerIdCookie, setProviderIdState] = useState(token && cookie.get(COOKIE_PROVIDER_ID));
  const { handleCloseLoginOrRegisterModal } = useLoginAndRegisterModal();
  const [generateToken, { loading: loggingIn }] = useLoginMutation();
  const { pub, sub } = useContext(EventContext);
  const { goTo } = useNavigation();
  const [user, setUser] = useState<AuthenticationInterface['user']>();

  const providerId = providerIdCookie?.split(':')?.[0];
  const jwt = token ? jwtDecode<JwtPayload>(token) : undefined;

  // Fetch logged user
  const { loading: loadingUser, refetch: refetchLoggedUser } = useGetLoggedUserQuery({
    onCompleted: (data) => {
      setUser(data?.backofficeUser);
    },
    skip: !token,
  });

  // Fetch logged provider
  const { data: providerData, loading: loadingProvider } = useGetLoggedProviderQuery({
    variables: { providerId: providerId as string },
    skip: !providerId || !token,
  });
  const provider = providerData?.provider;

  useEffect(() => {
    const orgs = (user?.buyers || []).map((b) => `${b.providerId}:${b.id}`);
    if (!providerIdCookie || (user && !orgs.includes(providerIdCookie))) {
      setBuyer(user?.buyers[0]);
    }
  }, [user]);

  const providerAddress = useMemo(() => {
    const providerAddress = providerData?.provider.address as ProviderAddress;

    return !!providerAddress
      ? {
          address: {
            company: provider?.commercialName as string,
            city: providerAddress?.city,
            complement: providerAddress?.complement,
            country_code: CountryCodeEnum.Br,
            firstname: provider?.commercialName as string,
            lastname: provider?.companyName as string,
            neighborhood: providerAddress?.neighborhood,
            number: providerAddress?.number,
            postcode: providerAddress?.postalCode,
            region: providerAddress?.state,
            street: [providerAddress?.lineAddress]!,
            telephone: provider?.contacts?.[0]?.phoneNumber || '000000000000',
            save_in_address_book: false,
          },
        }
      : null;
  }, [providerData]);

  const setToken = (token) => {
    if (token) setAuthCookie(token);
    else setAuthCookie(); // remove cookie
    setTokenState(token);
  };

  const setBuyer = async (buyer?: AuthenticatedBuyer) => {
    let id;
    if (buyer) {
      id = `${buyer.providerId}:${buyer.id}`;
      cookie.set(COOKIE_PROVIDER_ID, id, { domain: process.env.COOKIE_DOMAIN });
      pub(Event.SET_PROVIDER_ID);
    } else {
      cookie.remove(COOKIE_PROVIDER_ID, { domain: process.env.COOKIE_DOMAIN });
    }

    setProviderIdState(id);
  };

  const login = async (email: string, password: string) => {
    const { data } = await generateToken({ variables: { email, password } });
    const token = data?.backofficeLogin?.token;
    if (token) {
      setToken(token);
      refetchLoggedUser();
      pub(Event.LOGIN);
      userLoggedIn(true);
      handleCloseLoginOrRegisterModal();
      return token;
    } else {
      throw data?.backofficeLogin?.error;
    }
  };

  const logout = async () => {
    setToken(undefined);
    setUser(undefined);
    setBuyer(undefined);
    userLoggedIn(false);
    handleCloseLoginOrRegisterModal();
    pub(Event.LOGOUT);
  };

  const isSoftLogin = useMemo(() => {
    if (jwt?.exp && jwt.exp * 1000 > Date.now()) {
      return false;
    } else {
      logout();
      return false;
    }
  }, [token]);

  useEffect(() => {
    // listen for graphql errors of unauthorized access to resource in order to logout provider
    sub(Event.APOLLO_UNAUTHORIZED_ACCESS, () => {
      logout().then(() => goTo('/'));
      pub(Event.LOGOUT);
    });
    sub(Event.LOGOUT, () => {
      cache.evict({ id: 'ROOT_QUERY' });
      cache.gc();
    });
    sub(Event.SET_PROVIDER_ID, () => {
      cache.evict({ id: 'ROOT_QUERY' });
      cache.gc();
    });
  }, []);

  // Temporary, log out users using an user-service issued token
  if (jwt?.iss === 'user-service') {
    logout().then(() => goTo('/'));
  }

  return (
    <AuthenticationContext.Provider
      value={{
        login,
        loggingIn,
        logout,
        token,
        jwt,
        isLoggedIn: !!token,
        user,
        provider,
        providerId,
        providerAddress,
        loadingProvider: loadingProvider || (!user && loadingUser),
        loadingUser,
        setToken,
        setBuyer,
        isSoftLogin,
        postalCode: provider?.address?.postalCode,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
