import { yupResolver } from '@hookform/resolvers';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  TextfieldCommonMasks,
  Typography,
} from '@objectedge/pecai-storefront-ds';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Breakpoint, useBreakpoint } from '~/utils/display';
import Yup from '~/utils/yup';
import { formStateValuesProps } from '../ModalCustomerRegistrationForm';
import styles from '../ModalCustomerRegistrationForm.module.scss';

interface PersonalDataFormProps {
  loading: boolean;
  handlePreviousFormStep: () => void;
  formStateValues: formStateValuesProps;
  setFormStateValues: React.Dispatch<React.SetStateAction<formStateValuesProps>>;
  handleSubmitAllFormData: (values: formStateValuesProps) => Promise<void>;
}

const schemaStep2 = Yup.object().shape({
  email: Yup.string().trim().email('Email inválido').required('Campo é obrigatório'),
  responsavel: Yup.string().trim().fullName('Nome e sobrenome são obrigatórios'),
  cpf: Yup.string().trim().cpf('CPF inválido').required('Campo é obrigatório'),
  password: Yup.string().trim().required('Campo é obrigatório').min(6, 'A senha deve ter pelo menos 6 caracteres'),
  confirmPassword: Yup.string()
    .trim()
    .required('Campo é obrigatório')
    .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
  celular: Yup.string()
    .trim()
    .matches(/\d{4,5}-\d{4}/g, 'Celular inválido')
    .required('Campo é obrigatório'),
  optIn: Yup.boolean().oneOf([true], 'É necessário concordar com os termos de uso para continuar'),
  policy: Yup.boolean().oneOf([true], 'É necessário concordar com as políticas de privacidade para continuar'),
});

const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  loading,
  handlePreviousFormStep,
  formStateValues,
  setFormStateValues,
  handleSubmitAllFormData,
}) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: formStateValues, mode: 'onSubmit', resolver: yupResolver(schemaStep2) });

  const isMiniMobileView = useBreakpoint({ max: Breakpoint.xs });
  const isMobileView = useBreakpoint({ max: Breakpoint.md });

  const handleSubmitAllDataFromForm = (values) => {
    const allData = { ...formStateValues, ...values };
    handleSubmitAllFormData(allData);
  };

  return (
    <Form onSubmit={handleSubmit(handleSubmitAllDataFromForm)} className={styles.formContent}>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="responsavel">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Nome Completo <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            {...register}
            className={styles.formInput}
            ref={register}
            name="responsavel"
            defaultValue={formStateValues.responsavel}
            onChange={(e) => setFormStateValues({ ...formStateValues, responsavel: e.target.value })}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite seu nome
              </Typography>
            }
            status={errors.responsavel ? 'danger' : 'default'}
            helperText={errors.responsavel?.message ?? ''}
            maxLength={100}
            data-qa="register-name"
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="cpf">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              CPF <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            register={register}
            name="cpf"
            defaultValue={formStateValues.cpf}
            onChange={(e) => setFormStateValues({ ...formStateValues, cpf: e.target.value })}
            mask={TextfieldCommonMasks.cpf}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite seu CPF
              </Typography>
            }
            status={errors.cpf ? 'danger' : 'default'}
            helperText={errors.cpf?.message ?? ''}
            className={styles.formInput}
            data-qa="register-cpf"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="celular">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Celular <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            register={register}
            name="celular"
            defaultValue={formStateValues.celular}
            onChange={(e) => setFormStateValues({ ...formStateValues, celular: e.target.value })}
            mask={TextfieldCommonMasks.phone}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite seu celular
              </Typography>
            }
            status={errors.celular ? 'danger' : 'default'}
            helperText={errors.celular?.message ?? ''}
            className={styles.formInput}
            data-qa="register-phone"
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="email">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              E-mail <span className="text-primary">*</span>
            </Typography>
          </label>
          <Input
            ref={register}
            name="email"
            defaultValue={formStateValues.email}
            onChange={(e) => setFormStateValues({ ...formStateValues, email: e.target.value })}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite seu e-mail
              </Typography>
            }
            status={errors.email ? 'danger' : 'default'}
            helperText={errors.email?.message ?? ''}
            className={styles.formInput}
            maxLength={255}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="password">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
              weight="bold"
            >
              Senha de acesso*
            </Typography>
          </label>
          <Input
            ref={register}
            name="password"
            defaultValue={formStateValues.password}
            onChange={(e) => setFormStateValues({ ...formStateValues, password: e.target.value })}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Digite uma senha
              </Typography>
            }
            type="password"
            status={errors.password ? 'danger' : 'default'}
            helperText={errors.password?.message ?? ''}
            className={styles.formInput}
            data-qa="register-password"
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="confirmPassword">
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
              weight="bold"
            >
              Confirme sua senha*
            </Typography>
          </label>
          <Input
            ref={register}
            name="confirmPassword"
            defaultValue={formStateValues.confirmPassword}
            onChange={(e) => setFormStateValues({ ...formStateValues, confirmPassword: e.target.value })}
            label={
              <Typography style={{ fontFamily: 'Roboto' }} variant="caption-1" color="gray-100" weight="normal">
                Confirme sua senha
              </Typography>
            }
            type="password"
            status={errors.confirmPassword ? 'danger' : 'default'}
            helperText={errors.confirmPassword?.message ?? ''}
            className={styles.formInput}
            data-qa="register-confirmpassword"
          />
        </Col>
      </Row>

      <Row>
        <Controller
          name="optIn"
          control={control}
          innerRef={register}
          className={styles.optin}
          defaultValue={true}
          render={(props) => (
            <FormGroup className="px-3 m-0">
              <Checkbox
                style={{ cursor: 'pointer' }}
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  setValue('optIn', !props.value);
                  setFormStateValues({ ...formStateValues, optIn: !props.value });
                }}
                className="align-items-start mt-2"
                checked={props.value}
                label={
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setValue('optIn', !props.value);
                      setFormStateValues({ ...formStateValues, optIn: !props.value });
                    }}
                  >
                    <Typography variant="body-small" color="gray-100" weight="light">
                      Concordo em receber e-mails, mensagens de Whatsapp e SMS sobre o(s) status dos meu(s) pedidos(s)
                      além de novidades do Peça Aí.
                    </Typography>
                  </div>
                }
              />
            </FormGroup>
          )}
        />
      </Row>
      <Row>
        {errors.optIn && (
          <Typography color="danger" variant="caption-1" component="div">
            <div style={{ padding: '0 46px' }}>{errors.optIn.message}</div>
          </Typography>
        )}
      </Row>
      <Row>
        <Controller
          name="policy"
          control={control}
          innerRef={register}
          className={styles.optin}
          defaultValue={true}
          render={(props) => (
            <FormGroup className="px-3 m-0">
              <Checkbox
                style={{ cursor: 'pointer' }}
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  setValue('policy', !props.value);
                  setFormStateValues({ ...formStateValues, policy: !props.value });
                }}
                className="align-items-start mt-2"
                checked={props.value}
                label={
                  <div>
                    <Typography variant="body-small" color="gray-100" weight="light">
                      Concordo com o uso de dados para compras e experiência conforme a{' '}
                      <a href="/politica-de-privacidade" target="_blank">
                        Política de Privacidade.
                      </a>
                    </Typography>
                  </div>
                }
              />
            </FormGroup>
          )}
        />
      </Row>
      <Row>
        {errors.policy && (
          <Typography color="danger" variant="caption-1" component="div">
            <div style={{ padding: '0 46px' }}>{errors.policy.message}</div>
          </Typography>
        )}
      </Row>
      <div className={styles.buttonContainer}>
        <Button onClick={handlePreviousFormStep} size="sm" color="secondary" outline>
          Voltar
        </Button>
        <Button disabled={loading} size="sm" type="submit">
          Finalizar cadastro
        </Button>
      </div>
    </Form>
  );
};

export default PersonalDataForm;
