import { yupResolver } from '@hookform/resolvers';
import { Button, Checkbox, Col, Form, FormGroup, Row, Select, Typography } from '@objectedge/pecai-storefront-ds';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Breakpoint, useBreakpoint } from '~/utils/display';
import Yup from '~/utils/yup';
import { formStateValuesProps, optionsProps } from '../ModalCustomerRegistrationForm';
import styles from '../ModalCustomerRegistrationForm.module.scss';

interface ShopDetailsFormProps {
  handleNextFormStep: () => void;
  handlePreviousFormStep: () => void;
  formStateValues: formStateValuesProps;
  setFormStateValues: React.Dispatch<React.SetStateAction<formStateValuesProps>>;
}

const optionsAverageClients: optionsProps[] = [
  { label: '0 a 3 carros/dia', value: 0 },
  { label: '4 a 6 carros/dia', value: 4 },
  { label: '7 a 15 carros/dia', value: 7 },
  { label: '+15 carros/dia', value: 15 },
];

const optionsEmployees: optionsProps[] = [
  { label: '0 a 3 funcionários', value: 0 },
  { label: '4 a 6 funcionários', value: 4 },
  { label: '7 a 15 funcionários', value: 7 },
  { label: '+15 funcionários', value: 15 },
];

const optionsElevators: optionsProps[] = [
  { label: '0 elevadores', value: 0 },
  { label: '1 a 3 elevadores', value: 1 },
  { label: '4 a 6 elevadores', value: 4 },
  { label: '+6 elevadores', value: 6 },
];

const optionsItems: optionsProps[] = [
  { label: 'Óleo do Motor', value: 0 },
  { label: 'Fluído de Freio', value: 1 },
  { label: 'Lubrificante', value: 2 },
  { label: 'Nenhum', value: 3 },
  { label: 'Aditivo do Radiador', value: 4 },
  { label: 'Bateria', value: 5 },
  { label: 'Pastilha de Freio', value: 6 },
  { label: 'Outro', value: 7 },
];

const schema = Yup.object().shape({
  employees: Yup.object().oneSelected('Campo é obrigatório'),
  elevators: Yup.object().oneSelected('Campo é obrigatório'),
  averageClients: Yup.object().oneSelected('Campo é obrigatório'),
  itemsAvailable: Yup.array().min(1, 'Selecione pelo menos um item'),
  itemsAvailableOther: Yup.string().when(['itemsAvailable'], {
    is: (itemsAvailable) => !!itemsAvailable.some((i) => i.label === 'Outro'),
    then: Yup.string().required('Obrigatório'),
  }),
});

const ShopDetailsForm: React.FC<ShopDetailsFormProps> = ({
  handleNextFormStep,
  handlePreviousFormStep,
  formStateValues,
  setFormStateValues,
}) => {
  const isMiniMobileView = useBreakpoint({ max: Breakpoint.xs });
  const isMobileView = useBreakpoint({ max: Breakpoint.md });

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
  } = useForm({ defaultValues: formStateValues, resolver: yupResolver(schema) });

  register('itemsAvailable');
  register('itemsAvailableOther');
  const itemsAvailable = watch('itemsAvailable');
  const saveData = (values) => {
    setFormStateValues({ ...formStateValues, ...values });
    handleNextFormStep();
  };

  return (
    <Form onSubmit={handleSubmit(saveData)} className={styles.formContent}>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="employees">
            <Typography
              style={{ marginBottom: '13px' }}
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Número de Funcionários<span className="text-primary ml-1">*</span>{' '}
            </Typography>
          </label>
          <Controller
            name="employees"
            control={control}
            as={<Select />}
            options={optionsEmployees}
            className={styles.formSelect}
            defaultValue={formStateValues.employees}
            onChange={(_, newValue) => setValue('employees', newValue)}
            label={
              <Typography
                style={{ marginTop: '6px', fontFamily: 'Roboto' }}
                variant="caption-1"
                color="gray-100"
                weight="normal"
              >
                Selecione o número de funcionários
              </Typography>
            }
            fixLabel={!!formStateValues.employees}
            status={errors.employees ? 'danger' : 'default'}
            helperText={errors.employees?.message ?? ''}
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="elevators">
            <Typography
              style={{ marginBottom: '13px' }}
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Número de Elevadores<span className="text-primary ml-1">*</span>{' '}
            </Typography>
          </label>
          <Controller
            name="elevators"
            control={control}
            as={<Select />}
            options={optionsElevators}
            className={styles.formSelect}
            defaultValue={formStateValues.elevators}
            onChange={(_, newValue) => setValue('elevators', newValue)}
            label={
              <Typography
                style={{ marginTop: '6px', fontFamily: 'Roboto' }}
                variant="caption-1"
                color="gray-100"
                weight="normal"
              >
                Selecione o número de elevadores
              </Typography>
            }
            fixLabel={!!formStateValues.elevators}
            status={errors.elevators ? 'danger' : 'default'}
            helperText={errors.elevators?.message ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="averageClients">
            <Typography
              style={{ marginBottom: '13px' }}
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Carros atendidos por dia<span className="text-primary ml-1">*</span>
            </Typography>
          </label>
          <Controller
            name="averageClients"
            control={control}
            as={<Select />}
            options={optionsAverageClients}
            className={styles.formSelect}
            defaultValue={formStateValues.averageClients}
            onChange={(_, newValue) => setValue('averageClients', newValue)}
            label={
              <Typography
                style={{ marginTop: '6px', fontFamily: 'Roboto' }}
                variant="caption-1"
                color="gray-100"
                weight="normal"
              >
                Quantidade de carros
              </Typography>
            }
            fixLabel={!!formStateValues.averageClients}
            status={errors.averageClients ? 'danger' : 'default'}
            helperText={errors.averageClients?.message ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <label className={styles.inputLabel}>
            <Typography
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Selecione quais itens estão disponíveis no seu estoque: <span className="text-primary">*</span>
            </Typography>
            <Typography
              variant={!isMobileView ? 'body-small' : isMiniMobileView ? 'body-xsmall' : 'body-small'}
              color="primary-900"
            >
              É obrigatório assinalar ao menos 1 (uma) das opções
            </Typography>
          </label>
          <FormGroup className={styles.itemsAvailable}>
            {optionsItems.map((i: optionsProps) => (
              <div className="d-flex" id={i.label}>
                <Checkbox
                  id={i.label}
                  checked={itemsAvailable.some((ia) => ia.value === i.value)}
                  style={{ cursor: 'pointer' }}
                  onChange={({ target }) => {
                    let newItemsAvailable = [...itemsAvailable];
                    if (target.checked) {
                      if (i.label === 'Nenhum') newItemsAvailable = [];
                      else newItemsAvailable = newItemsAvailable.filter((i) => i.label !== 'Nenhum');
                      newItemsAvailable.push(i);
                    } else newItemsAvailable = newItemsAvailable.filter((ia) => ia.value !== i.value);
                    setValue('itemsAvailable', newItemsAvailable);
                  }}
                  label={i.label}
                />
                {i.label === 'Outro' && (
                  <div>
                    <input
                      name="itemsAvailableOther"
                      disabled={!itemsAvailable.some((ia) => ia.value === i.value)}
                      type="text"
                      defaultValue={formStateValues.itemsAvailableOther}
                      className={styles.simple}
                      onChange={({ target }) => setValue('itemsAvailableOther', target.value)}
                    />
                    {errors.itemsAvailableOther && (
                      <Typography variant="caption-1" color="danger">
                        {errors.itemsAvailableOther?.message}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            ))}
          </FormGroup>
          {errors.itemsAvailable && (
            <Typography variant="caption-1" color="danger">
              {(errors.itemsAvailable as any)?.message}
            </Typography>
          )}
        </Col>
      </Row>

      <div className={styles.buttonContainer}>
        <Button onClick={handlePreviousFormStep} size="sm" color="secondary" outline>
          Voltar
        </Button>
        <Button type="submit" size="sm">
          Próximo passo
        </Button>
      </div>
    </Form>
  );
};

export default ShopDetailsForm;
